.pt-sans-regular {
  font-family: "PT Sans", sans-serif;
  src: url("/public/assets/fonts/PTSans-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

.pt-sans-bold {
  font-family: "PT Sans", sans-serif;
  src: url("/public/assets/fonts/PTSans-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

.pt-sans-regular-italic {
  font-family: "PT Sans", sans-serif;
  src: url("/public/assets/fonts/PTSans-Italic.ttf");
  font-weight: 400;
  font-style: italic;
}
