.App {
  max-width: 1100px;
  text-align: left;
  background-color: white;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

#root {
  max-width: 1000px;
  margin: auto auto;
  border: 1px solid #ddd;
}

body {
  margin: 0;
  padding: 1em;
  font-family: "PT Sans", sans-serif;
  font-size: 1.2em;
  line-height: 1.4em;
  background-color: #c3b5a1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.container {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

h1 {
  font-size: 1.4em;
  line-height: 1.6em;
}

h2 {
  font-size: 1.1em;
  line-height: 1.3em;
}

.pt-sans-regular {
  font-family: "PT Sans", sans-serif;
  src: url("/public/assets/fonts/PTSans-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

.pt-sans-bold {
  font-family: "PT Sans", sans-serif;
  src: url("/public/assets/fonts/PTSans-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

.pt-sans-regular-italic {
  font-family: "PT Sans", sans-serif;
  src: url("/public/assets/fonts/PTSans-Italic.ttf");
  font-weight: 400;
  font-style: italic;
}

header {
  border: 1px solid red;
}

.navbar {
  padding: 1em;
  background-color: #fef7e5;
  position: relative;
}

.logo {
  height: auto;
  max-width: 25vmin;
}
.logo img {
  width: 100%;
}

/* Hamburger */
.hamburger {
  height: 2em;
  width: 2em;
}

.menu-icon .hamburger .hamburger .line,
.menu-icon .cross .hamburger .line {
  position: absolute;
  display: block;
  height: 4px;
  width: 2em;
  border-radius: 2px;
  transition: 0.5s;
  transform-origin: center;
}

.menu-icon .hamburger .hamburger .line {
  background: green;
}

.menu-icon .cross .hamburger .line {
  background: #c50429;
}

.line:nth-child(1) {
  top: 1.3em;
}

.line:nth-child(2) {
  top: 1.8em;
}

.line:nth-child(3) {
  top: 2.3em;
}

.menu-icon .cross .hamburger .line:nth-child(1) {
  transform: translate(0, 0.4em) rotate(-45deg);
  background: #c50429;
}

.menu-icon .cross .hamburger .line:nth-child(2) {
  opacity: 0;
}

.menu-icon .cross .hamburger .line:nth-child(3) {
  transform: translate(0, -0.6em) rotate(45deg);
  background: #c50429;
}

.menu-icon {
  display: none;
}

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 1.8em;
}

.nav-elements ul a {
  font-size: 1.1em;
  font-weight: 400;
  color: #c3b5a1;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: #574c4c;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #574c4c;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}
@media (max-width: 600px) {
  .logo {
    display: none;
  }
  .menu-icon {
    display: block;
    cursor: pointer;
  }
  .nav-elements {
    position: absolute;
    right: 0;
    top: 60px;
    background-color: #fef7e5;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.3s ease-in;
    overflow: hidden;
  }
  .nav-elements.active {
    width: 270px;
  }
  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }
  .nav-elements ul li {
    margin-right: unset;
    margin-top: 22px;
  }
}
footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 0.4em 1em;
  font-size: 0.8em;
  text-align: center;
  background-color: #eee;
}

.content {
  padding: 1em;
}

table.flex {
  width: 100%;
}
table.flex tr,
table.flex td {
  padding: 0;
  padding-top: 0.4em;
  vertical-align: top;
}

@media (max-width: 600px) {
  table.flex tr {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 1.2em;
  }
  table.flex td:nth-child(1) {
    font-weight: bold;
  }
}

