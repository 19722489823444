.content {
  padding: 1em;
}


table.flex {

  width: 100%;

  tr,
  td {
    padding: 0;
    padding-top: 0.4em;
    vertical-align: top;
  }

}


@media (max-width: 600px) {

  table.flex {

    tr {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-top: 1.2em;
    }

    td:nth-child(1) {
      font-weight: bold;
    }

  }

}