#root {
  max-width: 1000px;
  margin: auto auto;
  border: 1px solid #ddd;
}
body {
  margin: 0;
  padding: 1em;
  font-family: 'PT Sans', sans-serif;
  font-size: 1.2em;
  line-height: 1.4em;
  background-color: $euc-gold;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}